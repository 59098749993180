// 岗位管理
// sle
<template>
  <div class="body flex-row justify-center">
    <div class="main flex justify-between">
      <!-- 左侧 -->
      <div class="leftSide">
        <!-- 搜索框 -->
        <div v-if="false" class="typeSearch flex-centent">
          <a-input-search
            v-model:value="left.searchInput"
            class="searchInput"
            placeholder="搜索"
            @search="leftSearch"
            @change="leftSearchChange"
            :maxlength="20"
          >
          </a-input-search>
          <RedoOutlined @click="refresh" />
        </div>
        <!-- 部门，岗位切换 -->
        <div class="leftType flex-centent">
          <a-radio-group
            v-model:value="left.leftType"
            button-style="solid"
            @change="leftTypeChange"
          >
            <a-radio-button class="radioLeft" value="1">组织</a-radio-button>
            <a-radio-button class="radioRight" value="2">岗位</a-radio-button>
          </a-radio-group>
        </div>
        <div class="list">
          <!-- 企业名称以及企业人数 -->
          <div class="all flex-row">
            <a
              @click="choiseAll()"
              class="title flex"
              :style="left.choise.id == 'all' ? left.ischoise : left.notChoise"
              :title="left.title + '(' + left.employeeSum + ') '"
            >
              <span class="left_title">{{ left.title }}</span
              ><span>
                ({{
                  left.employeeSum > 9999
                    ? Math.floor(left.employeeSum / 1000) / 10 + "万"
                    : left.employeeSum
                }})</span
              >
            </a>
            <div v-if="disabled" class="leftitem">
              <a @click="addLeftItem()" class="plusicon">
                <PlusOutlined style="font-size: 20px"></PlusOutlined>
              </a>
            </div>
          </div>
          <!-- 当前岗位列表 -->
          <div class="leftList">
            <a-spin :spinning="left.spinning">
              <a-list
                item-layout="horizontal"
                :data-source="left.list"
                :split="false"
              >
                <template #renderItem="{ item }">
                  <div class="leftitem flex-row">
                    <!-- <ul>
                    <li @click="leftItemChange(item)">
                      {{ item.employeePostName }}
                    </li>
                  </ul> -->
                    <a class="flex align-center leftItemTitle">
                      <a-list-item
                        @click="leftItemChange(item)"
                        :style="
                          left.choise.id == item.id
                            ? left.ischoise
                            : left.notChoise
                        "
                        :title="
                          item.employeePostName +
                          '(' +
                          item.employeeCount +
                          ') '
                        "
                      >
                        <span class="employeePostName">{{
                          item.employeePostName
                        }}</span>
                        <span
                          >({{
                            item.employeeCount > 9999
                              ? Math.floor(item.employeeCount / 1000) / 10 +
                                "万"
                              : item.employeeCount
                          }})</span
                        >
                      </a-list-item>
                      <a-dropdown
                        :disabled="moreDisabled"
                        :trigger="['click']"
                        v-if="item.id != '' && disabled"
                      >
                        <a
                          :style="
                            left.choise.id == item.id
                              ? left.ischoise
                              : left.notChoise
                          "
                          @click.prevent
                        >
                        <span style="font-size: 16px;">
                          <MoreOutlined />
                        </span>
                        </a>
                        <template #overlay>
                          <a-menu>
                            <a-menu-item
                              key="edit"
                              @click="editLeftItem(item.id)"
                            >
                              <div class="flex">
                                <img src="@/assets/bianji_icon.png" alt="" />
                                <a style="color: black; margin-left: 6px"
                                  >编辑</a
                                >
                              </div>
                            </a-menu-item>
                            <a-menu-item key="del">
                              <a-popconfirm
                                cancelText="取消"
                                okText="确认"
                                :title="
                                  '是否确认删除 ' +
                                  item.employeePostName +
                                  (item.employeeCount > 0
                                    ? ' ?当前岗位下还有' +
                                      item.employeeCount +
                                      '人'
                                    : '')
                                "
                                @confirm="submitDelLeftItem(item.id)"
                              >
                                <div class="flex">
                                  <img src="@/assets/icon_shanchu.png" alt="" />
                                  <a style="color: black; margin-left: 6px"
                                    >删除</a
                                  >
                                </div>
                              </a-popconfirm>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </a>
                  </div>
                </template>
              </a-list>
            </a-spin>
          </div>
        </div>
      </div>

      <!-- 右侧 -->
      <div class="right" :style="{ minHeight: minHeight + 'px' }">
        <div class="head flex-row align-center justify-between">
          <div class="title">{{ left.choise.name }}</div>
          <!-- <div class="flex right-button">
            <img
              v-if="headButtonVisible"
              class="img"
              src="@/assets/yi_bianji.png"
              alt=""
            />
            <div class="button" v-if="headButtonVisible" @click="jurisdiction">
              编辑权限
            </div>
          </div> -->
          <div class="flex" v-if="applicationDisabled && headButtonVisible">
            <img class="img" src="@/assets/yi_bianji.png" />
            <div class="button" @click="jurisdiction">编辑权限</div>
          </div>
          <div class="flex" v-if="!applicationDisabled && headButtonVisible">
            <img class="img" src="@/assets/wei_bianji.png" />
            <div class="buttonHide" :title="title">编辑权限</div>
          </div>
        </div>
        <div class="line_h"></div>
        <div class="buttonGroup">
          <div class="buttons flex-row align-center justify-between">
            <a-input-search
              class="search"
              v-model:value="right.searchInput"
              placeholder="搜索"
              @search="rightSearch"
              @change="rightSearchChange"
              :maxlength="20"
            >
            </a-input-search>
            <span>
              <a-button
                :disabled="!disabled"
                :title="!disabled == true ? title : ''"
                class="button"
                v-if="headButtonVisible"
                @click="addEmployee"
              >
                添加成员
              </a-button>
              <a-button
                :disabled="!disabled"
                :title="!disabled == true ? title : ''"
                class="button"
                v-if="headButtonVisible"
                @click="removeEmployeeList"
              >
                移除成员
              </a-button>
            </span>
          </div>
        </div>
        <div class="table">
          <a-spin :spinning="right.spinning">
            <a-table
              :columns="columns"
              :data-source="right.list"
              bordered
              :rowKey="(record) => record.id"
              :pagination="pagination"
              @change="paginationChange"
              :row-selection="{
                selectedRowKeys: right.selectedRows,
                onChange: onSelectChange,
                fixed: true,
              }"
              :scroll="{ x: 1300 }"
            >
              <template #employeePost="{ record }">
                <a-tag
                  v-for="item of record.employeePost"
                  color="blue"
                  :key="item"
                  :title="record.employeePost"
                >
                  {{ item }}</a-tag
                >
              </template>
              <template #isBind="{ record }">
                <a-tooltip>
                  <template #title>{{ record.isBind }}</template>
                  <span class="ecllipsis" style="width: 100%">
                    {{ record.isBind === false ? "否" : "是" }}
                  </span>
                </a-tooltip>
              </template>
              <template #operation="{ record }">
                <div v-if="headButtonVisible" class="editable-row-operations">
                  <span class="flex-row justify-around">
                    <a-popconfirm
                      v-if="disabled"
                      cancelText="取消"
                      okText="确认"
                      title="是否确认移除"
                      @confirm="submitRemoveEmployee(record)"
                    >
                      <a>移除</a>
                    </a-popconfirm>
                    <div v-if="!disabled" :title="title" class="hide">移除</div>
                  </span>
                </div>
                <div v-if="!headButtonVisible">
                  <a
                    style="color: #aaa; cursor: not-allowed"
                    onclick="return false;"
                    >移除</a
                  >
                </div>
              </template>
            </a-table>
          </a-spin>
        </div>

        <!-- 新增/编辑岗位窗口 -->
        <postDuty-edit-view
          ref="postDuty"
          @postDutyEditBeforeReload="postDutyEditBeforeReload"
        />
        <!-- 批量删除确认弹窗 -->
        <a-modal
          v-model:visible="delListVisible"
          title="移除成员"
          :maskClosable="false"
          ok-text="确认"
          cancel-text="取消"
          :confirmLoading="confirmLoading"
          @ok="submitRemoveEmployeeList()"
        >
          确认移除选中的成员吗？
        </a-modal>
        <!-- 添加成员弹窗 -->
        <a-modal
          :confirmLoading="employeeToPostView.spinning"
          v-model:visible="employeeToPostView.visible"
          title="添加成员"
          :maskClosable="false"
          ok-text="确认"
          cancel-text="取消"
          @ok="submitAddEmployee"
          @cancel="closeEmployeeView"
        >
          <a-spin :spinning="employeeToPostView.spinning"> </a-spin>
          <a-tree
            :key="employeeToPostView.key"
            checkable
            :load-data="getEmployeeSelectList"
            :tree-data="employeeToPostView.treeData"
            v-model:checkedKeys="employeeToPostView.checkedKeys"
          />
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../../components/sameVariable'
import { isEmpty, addressBookJurisdiction, tenantIsComplete, applicationJurisdiction } from '@/assets/common.js'
import PostDutyEditView from './PostDutyEditView.vue'
import { getTableColumnData } from '@/api/tableAbout/tableAPI'
import { RedoOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    'postDuty-edit-view': PostDutyEditView,
    MoreOutlined,
    RedoOutlined,
    PlusOutlined,
  },
  data () {
    return {
      title: '暂无权限',
      disabled: false,
      disabledTenant: false,
      moreDisabled: false,
      applicationDisabled: false,
      permissiondisabled: false,
      module: 'addressBook',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
      }, // 分页部分
      minHeight: '400',
      left: {
        title: '',
        leftType: '2', // 当前选中的类型
        searchInput: '', // 搜索栏内容
        isSearch: false, // 是否在搜索中
        spinning: false, // 是否加载
        list: [], // 左侧列表
        employeeSum: '', // 人员总数
        choise: {
          id: '', // 选中的id
          name: '', // 选中的名称
        }, // 当前选中
        ischoise: 'color:#3399ff', // 选中时颜色
        notChoise: 'color:black', // 未选中时颜色
      }, // 左侧
      right: {
        spinning: false, // 是否加载
        searchInput: '', // 搜索栏内容
        isSearch: false, // 是否在搜索中
        list: [], // 右侧列表
        selectedRows: [],
        totle: [],
      }, // 右侧
      columns: [],
      headButtonVisible: false, // 编辑按钮是否显示
      delListVisible: false, // 批量删除确认弹框是否显示
      confirmLoading: false, // 确认移除按钮是否加载
      employeeToPostView: {
        key: 1,
        spinning: false, // 是否加载
        visible: false, // 弹窗是否显示
        treeData: [], // 树状图
        checkedKeys: [],
      }, // 添加成员弹窗
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.getTableColumnData()
    this.loadLeftList()
    this.left.choise.id = 'all'
    this.left.choise.name = this.left.title
    this.choiseAll()
    this.getJurisdiction()
    const tenantList = JSON.parse(localStorage.getItem('tenantList'))
    this.left.title = tenantList.enterpriseName
    this.left.choise.name = this.left.title
  },
  // 方法
  methods: {
    // 编辑权限
    jurisdiction () {
      window.location.href = '/EnterpriseManagement/Application'
    },
    // 获取权限
    getJurisdiction () {
      this.disabled = addressBookJurisdiction()
      this.disabledTenant = tenantIsComplete()
      this.applicationDisabled = applicationJurisdiction()
      if (this.disabledTenant === false) {
        this.disabled = false
      }
      if (this.disabled === true) {
        this.moreDisabled = null
      }
      // if (this.applicationDisabled === false) {
      //   this.permissiondisabled = false
      // }
    },
    // 获取当前table的列信息
    async getTableColumnData () {
      const data = await getTableColumnData('InteriorLinkMan', 'addressBook', true)
        .catch(err => {
          this.spinning = false
          console.log(err)
        })
      const list = data
      const arr = []
      list.map((item, index) => {
        arr.push(Object.assign({}, item, { width: 150 }))
      })
      this.columns = arr
    },
    // #region 左侧列表事件
    // 获取人员总数
    getEmployeeCount () {
      api
        .get('/api/app/employee/select-list-by-depment-id')
        .then(({ data }) => {
          this.pagination.total = data.length
          this.left.employeeSum = data.length
        })
        .catch(err => {
          // this.$message.error('获取人数失败')
          console.log(err)
        })
    },
    // 刷新
    refresh () {
      this.loadLeftList()
      this.getEmployeeCount()
    },
    // 左侧搜索栏变化事件
    leftSearchChange () {
      if (isEmpty(this.left.searchInput) && this.left.isSearch) {
        this.left.isSearch = false
        this.loadLeftList()
      }
    },
    // 左侧搜索
    leftSearch (e) {
      if (isEmpty(this.left.searchInput)) {
        return
      }
      this.left.isSearch = true
      this.loadLeftList()
    },

    // 左侧类型变更跳转至部门页面
    leftTypeChange () {
      this.$router.push({ path: '/AddressBook/Department' })
    },

    // 左侧企业名称 点击事件
    choiseAll () {
      // 当前选中项更新为企业名称，选中项id更新为all，当前所在页更新为1，不显示右侧按钮，选中项清空，页码总数为企业人数，获取成员列表
      this.right.selectedRows = []
      this.pagination.current = 1
      this.right.searchInput = ''
      this.right.isSearch = false
      this.left.choise.name = this.left.title
      this.left.choise.id = 'all'
      this.headButtonVisible = false
      this.getAllTableList()
      this.getEmployeeCount()
    },
    // 左侧岗位列表，选项变更事件
    leftItemChange (item) {
      this.right.selectedRows = []
      this.pagination.current = 1
      this.right.searchInput = ''
      this.right.isSearch = false
      this.left.choise.name = item.employeePostName
      this.left.choise.id = item.id
      this.headButtonVisible = !isEmpty(item.id)
      this.pagination.total = item.employeeCount
      this.getListByLeftItem()
    },
    // 获取岗位列表数据
    loadLeftList () {
      this.left.spinning = true
      api
        .get('/api/app/employee-post/employee-post-list-group', {
          params: {
            SearchInput: this.left.searchInput,
          },
        })
        .then(({ data }) => {
          this.left.spinning = false
          this.left.list = data
          for (let i = 0; i < data.length; i++) {
            if (!data[i].departmentName === '未分配') {
              // console.log(data[i].departmentName)
              this.choiseAll()
            }
          }
          // this.left.list.forEach(element => {
          //   const index = element.employeePostName.indexOf('未分配')
          //   if (index === -1) {
          //     this.choiseAll()
          //   }
          // })
        })
        .catch(err => {
          this.left.spinning = false
          // this.$message.error('获取岗位列表失败')
          console.log(err)
        })
    },

    // #endregion

    // #region 岗位管理部分
    // 添加岗位按钮，呼出添加岗位窗口
    addLeftItem () {
      this.$refs.postDuty.addPostDuty()
    },
    // 编辑岗位按钮，呼出编辑岗位窗口
    editLeftItem (id) {
      this.$refs.postDuty.editPostDuty(id)
    },
    // 添加，编辑后刷新事件
    postDutyEditBeforeReload (name, id) {
      if (this.left.choise.id === id) {
        this.left.choise.name = name
      }
      this.loadLeftList()
      this.getRightData()
    },
    // 确认删除岗位操作
    submitDelLeftItem (id) {
      this.left.spinning = true
      api
        .delete('/api/app/employee-post/post', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.loadLeftList()
          if (this.left.choise.id === id) {
            this.choiseAll()
          } else {
            this.getRightData()
          }
        })
        .catch(err => {
          this.left.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // #endregion

    // #region 右侧列表
    // 成员列表 页码变更
    paginationChange (e) {
      this.pagination.current = e.current
      this.getRightData()
    },
    // 获取右侧列表数据
    getRightData () {
      if (this.left.choise.id === 'all') {
        this.getAllTableList()
      } else {
        this.getListByLeftItem()
      }
    },
    // 成员列表选择事件
    onSelectChange (selectedRows) {
      this.right.selectedRows = selectedRows
    },

    // 获取所有人员数据
    getAllTableList () {
      this.right.spinning = true
      api
        .get('/api/app/employee/paging-employee-list-pC', {
          params: {
            SearchInput: this.right.searchInput,
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
          },
        })
        .then(({ data }) => {
          this.right.spinning = false
          this.right.list = data.list
          // console.log(this.right.list, 'arsafsadfsd')
          // if (this.right.searchInput === '') {
          //   this.pagination.total = this.left.employeeSum
          // } else {
          this.pagination.total = data.pageCount
          // }
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('获取成员数据失败')
          console.log(err)
        })
    },
    // 根据指定岗位显示其下成员数据
    getListByLeftItem () {
      this.right.spinning = true
      api
        .get('/api/app/employee/employee-list-by-post-id-pC', {
          params: {
            input: this.left.choise.id,
            SearchInput: this.right.searchInput,
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
          },
        })
        .then(({ data }) => {
          this.right.spinning = false
          this.right.list = data.list
          // this. =data.list
          // if (this.right.searchInput === '') {
          //   this.pagination.total = this.left.employeeSum
          // } else {
          this.pagination.total = data.pageCount
          // }
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('获取成员数据失败')
          console.log(err)
        })
    },
    // #endregion

    // #region table 上方按钮
    // 右侧搜索栏变化事件
    rightSearchChange () {
      if (isEmpty(this.right.searchInput) && this.right.isSearch) {
        this.right.isSearch = false
        this.pagination.current = 1
        this.getRightData()
      }
    },

    // 右侧搜索
    rightSearch (e) {
      // console.log(e)
      if (isEmpty(this.right.searchInput)) {
        return
      }
      this.pagination.current = 1
      this.right.isSearch = true
      this.getRightData()
    },

    // 批量移除按钮，呼出确认页面
    removeEmployeeList () {
      if (this.right.selectedRows.length === 0) {
        this.$message.error('请选择需要移除的成员')
        return
      }
      this.delListVisible = true
    },
    // 确认批量移除
    submitRemoveEmployeeList () {
      const list = this.right.selectedRows
      this.removeEmployee(list)
    },
    // 确认单个移除
    submitRemoveEmployee (item) {
      const list = [item.id]
      this.removeEmployee(list)
    },
    // 移除动作
    removeEmployee (list) {
      this.confirmLoading = true
      const id = this.left.choise.id
      this.right.spinning = true
      api
        .post('/api/app/employee-post/batch-remove-employee-from-post', {
          mainId: id,
          sublistIds: list,
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.delListVisible = false
          this.confirmLoading = false
          this.right.spinning = false
          this.loadLeftList()
          this.getRightData()
        })
        .catch(err => {
          this.right.spinning = false
          this.delListVisible = false
          this.confirmLoading = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // #endregion

    // #region 添加成员
    // 关闭添加成员页面弹窗
    closeEmployeeView () {
      this.employeeToPostView.visible = false
      this.employeeToPostView.treeData = []
      this.employeeToPostView.checkedKeys = []
    },
    // 添加成员按钮，呼出添加成员弹窗
    addEmployee () {
      this.employeeToPostView.key++
      this.employeeToPostView.visible = true
      this.loadDepartmentList()
    },
    // 获取公司部门结构
    loadDepartmentList () {
      this.employeeToPostView.spinning = true
      api
        .get('/api/app/department/department-list-group', {
          params: {
            SearchInput: this.employeeToPostView.searchInput,
          },
        })
        .then(({ data }) => {
          this.employeeToPostView.spinning = false
          const treeData = []
          data.forEach(element => {
            const obj = {
              title: element.departmentName,
              key: element.id,
              checkable: false,
            }
            treeData.push(obj)
          })
          this.employeeToPostView.treeData = treeData
        })
        .catch(err => {
          this.employeeToPostView.spinning = false
          // this.$message.error('获取部门列表失败')
          console.log(err)
        })
    },
    // 获取当前部门节点下的员工信息
    async getEmployeeSelectList (treeNode) {
      if (treeNode.dataRef.children) {
        return
      }
      const id = treeNode.dataRef.key
      const treeData = this.employeeToPostView.treeData
      await api
        .get('/api/app/employee-post/select-list-by-depment-id', {
          params: { postId: this.left.choise.id, departmentId: id },
        })
        .then(({ data }) => {
          const children = []
          data.forEach(element => {
            const obj = {
              title: element.name,
              key: element.linkKey,
              isLeaf: true,
            }
            children.push(obj)
          })
          for (let i = 0; i < treeData.length; i++) {
            const item = treeData[i]
            if (item.key === id) {
              this.employeeToPostView.treeData[i].children = children
              return
            }
          }
        })
        .catch(err => {
          // this.$message.error('获取成员数据失败')
          console.log(err)
        })
    },
    // 确认添加按钮
    submitAddEmployee () {
      const list = this.employeeToPostView.checkedKeys
      const id = this.left.choise.id
      this.employeeToPostView.spinning = true
      api
        .post('/api/app/employee-post/batch-add-employee-to-post', {
          mainId: id,
          sublistIds: list,
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.employeeToPostView.spinning = false
          this.loadLeftList()
          this.closeEmployeeView()
          this.getRightData()
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // #endregion
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.body {
  width: 100%;
  .main {
    width: 82%;
    .leftSide {
      border-radius: 5px;
      width: 14%;
      height: calc(90% - 135px);
      background: white;
      position: fixed;
      z-index: 10;
      .choise {
        color: #3399ff;
      }
      .typeSearch {
        margin-top: 20px;
        width: 100%;
        height: 36px;
        .searchInput {
          width: 75%;
          background: #fff;
          margin-right: 10px;
        }
      }
      .leftType {
        margin-top: 20px;
        width: 100%;
        height: 36px;
        .radioLeft {
          padding: 0 25px;
          border-radius: 2px 0px 0px 2px;
        }
        .radioRight {
          padding: 0 25px;
          border-radius: 0px 2px 2px 0px;
        }
      }
      .list {
        max-height: 72%;
        overflow: auto;
        overflow-x: hidden;
      }
      .all {
        margin-top: 10px;
        padding-left: 10%;
        width: 100%;
        height: 36px;
        font-size: 18px;
        letter-spacing: 0px;
        a: {
          color: black;
        }
        .leftitem {
          margin-left: 10%;
          width: 100%;
          .plusicon {
            font-size: 18px;
            position: absolute;
            left: 86%;
            color: grey;
          }
          .plusicon::before {
            content: "添加岗位";
            position: absolute;
            width: 100px;
            background-color: #062b45;
            color: white;
            text-align: center;
            padding: 10px;
            line-height: 1.2;
            border-radius: 6px;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.6s;
            margin-left: 100%;
            bottom: -10px;
            font-size: 0.75em;
            visibility: hidden;
            word-break: break-all;
          }
          .plusicon:hover:before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .left_title {
        max-width: 85%;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .title {
        width: 85%;
        span {
          display: inline-block;
        }
        // overflow: hidden;
        white-space: nowrap;
        // text-overflow: ellipsis;
      }
      .employeePostName {
        max-width: 85%;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .leftList {
        width: 100%;
        margin-top: 5px;
        .leftitem {
          margin-left: 10%;
          width: 100%;
          .plusicon {
            margin-top: 14px;
            font-size: 18px;
            position: absolute;
            left: 86%;
            color: grey;
          }
          .plusicon::before {
            content: "添加类型";
            position: absolute;
            width: 100px;
            background-color: #062b45;
            color: white;
            text-align: center;
            padding: 10px;
            line-height: 1.2;
            border-radius: 6px;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.6s;
            margin-left: 100%;
            bottom: -10px;
            font-size: 0.75em;
            visibility: hidden;
            word-break: break-all;
          }
          .plusicon:hover:before {
            opacity: 1;
            visibility: visible;
          }
        }
        .leftItemTitle {
          width: 79%;
        }
        .ant-list-item {
          width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          justify-content: flex-start;
        }
        .ant-dropdown-trigger {
          position: absolute;
          left: 87%;
        }
      }
    }
    .right {
      margin-left: 19%;
      width: 81%;
      background-color: #fff;
      border-radius: 5px;
      .head {
        letter-spacing: 1px;
        background: #fff;
        padding: 0px 20px;
        border-radius: 5px;
        height: 60px;
        font-size: 18px;
        .right-button {
          position: relative;
        }
        .title {
          max-width: 300px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .img {
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }
        .button {
          font-size: 16px;
          cursor: pointer;
          color: #3479db;
        }
        .buttonHide {
          font-size: 16px;
          color: #b7b7b7;
          cursor: not-allowed;
        }
      }
      .buttonGroup {
        height: 80px;
        .button {
          margin-right: 20px;
        }
        .buttons {
          height: 100%;
        }
        .search {
          flex-direction: row-reverse;
          width: 200px;
          margin-left: 24px;
        }
        .invite {
          color: black;
          margin-left: 40px;
        }
      }
      .table {
        padding: 0px 24px;
        margin: 0;
      }
      .hide {
        color: #b7b7b7;
        cursor: not-allowed;
      }
    }
  }
}

.list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 3px;
}
.list::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 10px;
}
.list::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

::v-deep(.ant-table-bordered .ant-table-thead) > tr > th {
  text-align: center;
  border-right: none;
  padding: 12px 10px;
}
::v-deep(.ant-table-bordered .ant-table-tbody) > tr > td {
  text-align: center;
  padding: 12px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: none;
}
::v-deep(.ant-table-bordered .ant-table-fixed-right) table {
  border-right: 1px solid #f0f0f0;
}

::v-deep(.ant-input-suffix){
  margin-left: 0px;
  padding-right:6px;
}
</style>
